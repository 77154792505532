import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RouterLinkService } from 'src/app/module/shared/services/router-link.service';
import { state } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})
export class PublicGuardService {

  constructor(private router: Router,
              private authService: AuthService,
              private routerLinkService: RouterLinkService) { }

          // tslint:disable-next-line: no-shadowed-variable
          canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
            console.log(route, state);
            if (!this.authService.userLoginStatus()) {
                return true;
            } else {
              if ((this.routerLinkService.beforeLoginRoutes()).includes(state.url)) {
                this.router.navigate([this.routerLinkService.library()]);
              } else {
                return true;
            }
          }

    }
}
