import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouterLinkService {

  constructor() { }


  login() {
    return '/public/login';
  }

  register() {
    return '/public/register';
  }

  forgetPassword() {
    return '/public/forgot-password';
  }

  resetPasswordRedirectLink() {
    return window.location.hostname + '/public/reset-password';
  }

  createCourse() {
    return '/user/library/create-course';
  }

  uploadScormFile(courseId) {
    return '/user/library/upload-course/' + courseId;
  }

  courseEnrollment(courseId, pageNo = 1) {
    return '/user/library/course-enrollments/' + courseId + '/' + pageNo;
  }

  createDestination() {
    return  '/user/destinations/create-destination';
  }

  createDispatch() {
    return '/user/dispatches/create-dispatch';
  }

  dispatchEnrollment(dispatchId) {
    return '/user/dispatches/dispatche-enrollments/' + dispatchId;
  }

  library() {
    return '/user/library';
  }

  destination() {
    return '/user/destinations';
  }

  dispatch() {
    return '/user/dispatches';
  }

  shareLink() {
    return '/user/invitations';
  }

  shareEnrollments(shareId) {
    return '/user/invitations/invitation-enrollments/' + shareId;
  }

  account() {
    return '/user/user-profile';
  }



  beforeLoginRoutes() {
    return [this.login(), this.register()];
  }


}
