import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './module/shared/shared.module';
import { HttpConfigInterceptor } from './core/interceptor/httpconfig.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatRadioModule} from '@angular/material/radio';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { ClipboardModule } from 'ngx-clipboard';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    NotifierModule,
    NgxUiLoaderModule,
    Ng2SearchPipeModule,
    // MatInputModule,
    // MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    // MatSelectModule.
    TextFieldModule,
    NgxSmartModalModule.forRoot(),
    ClipboardModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
