import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RouterLinkService } from 'src/app/module/shared/services/router-link.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private router: Router,
              private authService: AuthService,
              private routerLinkService: RouterLinkService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log(route, state);
    if (this.authService.userLoginStatus()) {
      return true;
    } else {
      this.router.navigate([this.routerLinkService.login()]);
    }
  }
}
