import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(router: Router) { }

  async loginSuccessResponseHandle(userData) {
    const userInfo = JSON.stringify(userData);
    await localStorage.setItem('userData', userInfo);
  }


  getUserDetails() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
      return userData;
    } else {
      return false;
    }
  }

  getUserToken() {
    return this.getUserDetails().apiToken;
  }

  getUserTokenForUrl() {
    return (this.getUserDetails().apiToken).replace('Bearer ', '');
  }

  userLoginStatus() {
    if (this.getUserToken()) {
      return true;
    } else {
      return false;
    }
  }

}
