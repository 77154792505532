import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as component from './index';
import { RouterModule } from '@angular/router';
import { RouterLinkService } from './services/router-link.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { AuthService } from 'src/app/core/services/auth.service';
import { AuthGuardService } from 'src/app/core/guards/auth-guard.service';
import { PublicGuardService } from 'src/app/core/guards/public-guard.service';



@NgModule({
  declarations: [component.HeaderComponent,
                 component.FooterComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NotifierModule
  ],
  exports: [
    component.HeaderComponent,
    component.FooterComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [ RouterLinkService,
                   AuthService,
                   AuthGuardService,
                   PublicGuardService]
    };
  }
 }
